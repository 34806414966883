/* eslint-disable max-len */
/* -------------------------------------------------------------------------- */
/*                           ABOUT US UNORDERED LIST                          */
/* -------------------------------------------------------------------------- */
export const List = [
  {
    id: 1,
    link: 'Orienter les choix stratégiques en vue d’intégrer le système R&I national dans l’Espace européen de la recherche.',
  },
  {
    id: 2,
    link: 'Définir les priorités nationales de R&I et en assurer la promotion au niveau du Comité de Programme Horizon 2020 avec l’appui des Conseillers Scientifiques du programme.',
  },
  {
    id: 3,
    link: 'Apporter soutien et assistance à la communauté à travers le réseau des Points de Contacts Nationaux.',
  },
  {
    id: 4,
    link: 'Consolider la participation tunisienne aux appels à projets.',
  },
  {
    id: 5,
    link: 'Réaliser des campagnes de sensibilisation aux opportunités du programme auprès de l’ensemble de la communauté scientifique tunisienne.',
  },
  {
    id: 6,
    link: 'Améliorer la contribution de la recherche scientifique au développement socioéconomique, en tant que composante du MESRS, et de fait, du Système National de Recherche et d’Innovation [SNRI].',
  },
];

/* -------------------------------------------------------------------------- */
/*            UNDERSTAND HORIZON PAGE - PRESENTATION UNORDERD LIST            */
/* -------------------------------------------------------------------------- */
export const HORIZON_PRESENTATION_LIST = [
  {
    id: 1,
    link: "Renforcer l'excellente de la recherche fondamentale et exploratoire",
  },
  {
    id: 2,
    link: 'Renforcer le lien entre R& I',
  },
  {
    id: 3,
    link: "Soutenir la mise en œuvre des priorités politiques de l'Union, notamment les objectifs de développement durable.",
  },
  {
    id: 4,
    link: 'Promouvoir une recherche et une innovation responsables',
  },
  {
    id: 5,
    link: 'Renforcer la dimension de genre dans tout le programme',
  },
  {
    id: 6,
    link: 'Renforcer les liens entre les secteurs et les disciplines',
  },
  {
    id: 7,
    link: 'Renforcer la coopération internationale',
  },
  {
    id: 8,
    link: 'Développer des infrastructures de recherche dans tout l’EER',
  },
  {
    id: 9,
    link: 'Attirer les talents, former et ﬁdéliser les chercheurs et les innovateurs dans l’EER',
  },
  {
    id: 10,
    link: 'Promouvoir la science ouverte',
  },
  {
    id: 11,
    link: "Encourager l'exploitation des résultats de la R&I",
  },
  {
    id: 12,
    link: 'Réaliser, par le biais de missions de R&I, des objectifs ambitieux dans un délai ﬁxé',
  },
  {
    id: 13,
    link: "Améliorer la relation et l'interaction entre la science et la société",
  },
  {
    id: 14,
    link: 'Accélérer la transformation industrielle',
  },
  {
    id: 15,
    link: 'Stimuler les activités de R&I dans les PME et start-ups',
  },
];

/* -------------------------------------------------------------------------- */
/*            UNDERSTAND HORIZON PAGE - PRESENTATION UNORDERD LIST            */
/* -------------------------------------------------------------------------- */
